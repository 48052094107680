import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nivelSelected:null,
    nivelColor:null,
    tipoJuegoSelected: null,
    menuBarBottom:true,
    tutorialStatus:true,
    scoreGame:0,
    scoreDescription:'',
    juegos: {
      'nivel1':[
          {
            tipo:'Percepción',
            nombre:'Frutas diferentes',
            dificultad:'Difícil',
            urlvista:'/frutas-diferentes',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1611629651/juegos-cramer/frutas-diferentes_xhhimu.svg'
          },
          {
            tipo:'Percepción',
            nombre:'Cuenta figuras',
            dificultad:'Media',
            urlvista:'/cuenta-figuras',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1611630525/juegos-cramer/cuenta_figuras_woq8bn.svg'
          },
          {
            tipo:'Percepción',
            nombre:'Formas distorsionadas',
            dificultad:'Difícil',
            urlvista:'/formas-distorsionadas',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1611630501/juegos-cramer/formas-distorsionadas_as9suq.svg'
          },
          {
            tipo:'Cálculo',
            nombre:'Cuenta formas en bloques',
            dificultad:'Medio',
            urlvista:'/cuenta-bloques',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1612230037/juegos-cramer/contar_bloques_xysf6s.png'
          },
          {
            tipo:'Cálculo',
            nombre:'Número mayor y menor',
            dificultad:'Medio',
            urlvista:'/numero-mayor-menor',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1612230037/juegos-cramer/serie_numerica_gt5t3k.png'
          },
          {
            tipo:'Cálculo',
            nombre:'Sumando latas',
            dificultad:'Difícil',
            urlvista:'/sumando-latas',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1612230037/juegos-cramer/sumar_latas_yeapdm.png'
          },
          {
            tipo:'Razonamiento',
            nombre:'Figuras en serie',
            dificultad:'Fácil',
            urlvista:'/figuras-en-serie',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1619804314/juegos-cramer/figuras_en_serie_yechsd.png'
          },
          {
            tipo:'Razonamiento',
            nombre:'Analogías',
            dificultad:'Fácil',
            urlvista:'/analogias',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1619804315/juegos-cramer/analogias_eggdvx.png'
          },
          {
            tipo:'Lenguaje',
            nombre:'Letras faltantes',
            dificultad:'Difícil',
            urlvista:'/letras-faltantes',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1619804295/juegos-cramer/letras_faltantes_hzkuxo.png'
          },
          {
            tipo:'Lenguaje',
            nombre:'Busca final de la palabra',
            dificultad:'Fácil',
            urlvista:'/busca-final',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1619804295/juegos-cramer/letra_final_ifbwdd.png'
          },
          {
            tipo:'Memoria',
            nombre:'Un objeto cambia',
            dificultad:'Fácil',
            urlvista:'/un-objeto-cambia',
            icon:'https://res.cloudinary.com/juegos-cramer/image/upload/v1619804295/juegos-cramer/objeto_cambia_ay3bjl.png'
          },
        ]
    }
  },
  mutations: {
    changeNivelSelected(state,nivel){
      state.nivelSelected = nivel
    },
    changeTipoJuegoSelected(state,tipo){
      state.tipoJuegoSelected = tipo
    },
    changeNivelColor(state,color){
      state.nivelColor= color
    },
    changeMenuBarBottom(state,val){
      state.menuBarBottom = val
    },
    changeTutorialStatus(state,val){
      state.tutorialStatus = val
    },
    changeScoreGame(state,num){
      state.scoreGame = num
    },
    changeScoreDescription(state,desc){
      state.scoreDescription = desc
    }
  },
  actions: {
  },
  modules: {
  }
})
