import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import {auth} from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path:'*',
    redirect:'/'
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/registrar',
    name: 'Registrar',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/editar-datos',
    name: 'EditarDatos',
    component: () => import('../views/ProfileEdit.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/perfil-usuario',
    name: 'PerfilUsuario',
    component: () => import('../views/ProfileView.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/nivel-detalle',
    name: 'Nivel',
    component: () => import('../views/NivelDetalleView.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/juegos',
    name: 'Juegos',
    component: () => import('../views/GamesMenu.vue'),
    meta:{
      requiresAuth:true,
    }
  }
  /* Rutas a vistas de juegos */
  /*percepcion nivel 1 */
  ,
  {
    path: '/frutas-diferentes',
    name: 'FrutasDiferentes',
    component: () => import('../views/nivel1/percepcion/FrutasDiferentes.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/cuenta-figuras',
    name: 'CuentaFiguras',
    component: () => import('../views/nivel1/percepcion/CuentaFiguras.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/formas-distorsionadas',
    name: 'FormasDistorsionadas',
    component: () => import('../views/nivel1/percepcion/FormasDistorsionadas.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  /*Calculo nivel 1 */
  {
    path: '/cuenta-bloques',
    name: 'CuentaBloques',
    component: () => import('../views/nivel1/calculo/CuentaBloques.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/numero-mayor-menor',
    name: 'NumeroMayorMenor',
    component: () => import('../views/nivel1/calculo/NumeroMayorMenor.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/sumando-latas',
    name: 'SumandoLatas',
    component: () => import('../views/nivel1/calculo/SumandoLatas.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  /*Razonamiento */
  {
    path: '/figuras-en-serie',
    name: 'FigurasEnSerie',
    component: () => import('../views/nivel1/razonamiento/FigurasEnSerie.vue'),
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: '/analogias',
    name: 'Analogias',
    component: () => import('../views/nivel1/razonamiento/Analogias.vue'),
    meta:{
      requiresAuth:true,
    },
  },
    /*Lenguaje */
  {
      path: '/letras-faltantes',
      name: 'LetrasFaltantes',
      component: () => import('../views/nivel1/lenguaje/LetrasFaltantes.vue'),
      meta:{
        requiresAuth:true,
      },
  },
  {
      path: '/busca-final',
      name: 'BuscaFinal',
      component: () => import('../views/nivel1/lenguaje/BuscaFinal.vue'),
      meta:{
        requiresAuth:true,
      },
  },
  /*Memoria */
  {
    path: '/un-objeto-cambia',
    name: 'ObjetoCambia',
    component: () => import('../views/nivel1/memoria/ObjetoCambia.vue'),
    meta:{
      requiresAuth:true,
    },
},



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function getCurrentUser(){
  return new Promise((resolve,reject)=>{
      const unsubscribe = auth.onAuthStateChanged(
        user =>{
          unsubscribe();
          resolve(user);
        },
        ()=>{
          reject();
        }
      );
  });
}

router.beforeEach(async (to,from,next)=>{
  const requiresAuth = to.matched.some(record=> record.meta.requiresAuth);
  //let u = await getCurrentUser()
  //console.log(u.uid)
  //requires auth && no user
  if(requiresAuth&&!(await getCurrentUser()))
  {
    next({name:'Login'})
  }
  //no requires auth && existe user
  else if(!requiresAuth && (await getCurrentUser()) )
  {
    const forEverView = to.matched.some(record=> record.meta.forEverView);
    if(forEverView){
      next()
    }
    else{
      next({name:'Home'})
    }
    
  }
  else{
    next();
  }
});


export default router
