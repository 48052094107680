<template>
    <div id="nav">
     <!--<router-link to="/">Home</router-link> | -->
      <div class="navbar">
          <div class="navbar__logo-brain">
              <img  class="navbar__logo" src="../assets/icons/brain-cramer3.svg" alt="brain">
              <div class="navbar__title-logo">
                  <title-menu :nivel="nivel" :sizetitle="sizetitle"></title-menu>
              </div>
          </div>
          <div class="navbar__items" v-if="menuBarBottom">
              <router-link to="/home">
                    <img src="../assets/icons/puzzle.svg"> 
                    <span class="text-menu">Inicio</span>
              </router-link>
              <router-link to="/perfil-usuario">
                    <img src="../assets/icons/man.svg">
                    <span class="text-menu">Perfil</span>
              </router-link>
          </div>
      </div>
    </div>
</template>
<script>
import TitleMenu from './TitleMenu.vue'
import {mapState} from 'vuex'
export default {
    name:'NavBar',
    props:['sizetitle','nivel'],
    components:{
        TitleMenu
    },
    computed:{
        ...mapState(['menuBarBottom'])
    }
}
</script>
<style scoped>
.navbar{
    width: 100%;
}
.navbar__logo-brain{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    /*position: fixed;
    top:0px;*/
}
.navbar__logo-brain div{
    width: 70%;
    text-align: center;
    font-size: 2em;
    color:#F23827;
}
.navbar__logo{
    width: 6em;
}
.navbar__items{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0px;
}
.text-menu{
    display: none;
}
.navbar__items a{
    text-align: center;
    width: 100%;
    background-color: #050259;
    padding: 1em;
}
.navbar__items a:first-child{
    border-right: #3A387C 1px solid;
}
.navbar__items a.router-link-exact-active {
  border-top:#F23827 4px solid ;
}
@media (min-width: 900px) and (orientation: landscape) {
    .navbar__items{
        position: static;
        width: 50%;
    }
    .navbar{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        background-color: #050259;
    }
    .navbar__logo-brain{
        width: 30%;
        padding: 0.5em;
    }
    .navbar__items a{
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: all 0.2s;
    }
    .text-menu{
        display: block;
        color:#F23827;
        font-size: 2em;
    }
    .navbar__items a:first-child{
        border-right: #3A387C 0px solid;
    }
    .navbar__items a:hover{
        border-bottom:#F23827 4px solid ;
    }
    .navbar__items a.router-link-exact-active {
        border-top: 0px;
        border-bottom:#F23827 4px solid ;
    }
}
</style>