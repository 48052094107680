<template>
    <div>
        <div class="title-container">
            <div :class="['title',sizetitle]">
                Juegos de inteligencia
            </div>
            <div v-if="nivel" class="level">
                Nivel {{nivel}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'TitleMenu',
    props:['nivel','sizetitle']
}
</script>
<style scoped>
    .title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .md{
        font-size: 0.5em;
    }
    .sm{
        font-size: 0.3em;
    }
    .level{
        font-size: 0.7em;
        color: white;
    }
    
</style>