import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/storage'
const firebaseConfig = {
    apiKey: "AIzaSyATpRq0AUKP9AglUIwGQilpeO0inRr3NCM",
    authDomain: "jugos-cramer.firebaseapp.com",
    projectId: "jugos-cramer",
    storageBucket: "jugos-cramer.appspot.com",
    messagingSenderId: "537872492716",
    appId: "1:537872492716:web:4607e59ccb7972019a1709",
    measurementId: "G-KFPZ7DL796"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();