<template>
  <div>
    <nav-bar v-if="loginStatus"></nav-bar>
      <div id="app">
        <router-view/>
      </div>
  </div>
  
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  components:{
    NavBar
  },
  data:()=>({
    loginStatus:false
  })
}
</script>
<style>
  body{
    margin:0px;
    padding:0px;
    font-family: 'Pathway Gothic One', sans-serif;
  }
</style>
